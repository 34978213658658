// branding configuration
$publication: 'coventrytelegraph';
$logo-dimensions: 600, 160;
$primary-color: #0074BC;
$header-additional: #4D4D4F;
$brand-hue: 203;
$brand-saturation: 100%;

$brand-color: hsl($brand-hue, $brand-saturation, 37%);
$highlight: hsl($brand-hue, $brand-saturation, 52%);
$hint: hsl($brand-hue, $brand-saturation,  97%);
$lowlight: hsl($brand-hue, $brand-saturation,  12%);
// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }

  #signin {

    .icon {
      color: $primary-color;
    }

    .label {
      color: $primary-color;

      span {
        box-shadow: inset 0 0 0 2px $primary-color;
      }
    }

    .logout-wrapper {

      .name {
        background-color: $header-additional;
        border: 2px solid $primary-color;
        color: $primary-color;
      }

    }

  }
}
